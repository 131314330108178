import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

export const APP_NAME = 'Fuse';

//SETUP
export const FACEBOOK_APP_ID = '1058019892597518';
export const GOOGLE_APP_ID =
  '174426117528-bs9c7tp4ha7jsintnd3a9a2vh0paol73.apps.googleusercontent.com';
export const STASHY_APP_ID = '1058019892597518';
export const STASHY_BASIC_APP_ID = '361548478601196';
export const INSTAGRAM_BUSINESS_APP_ID = import.meta.env.VITE_INSTAGRAM_BUSINESS_APP_ID;
export const DROPBOX_APP_ID = 'hqfygxdupm79fkt';

//Account Dropdowns
export const INSTAGRAM_BASIC = 'instagram-basic';
export const INSTAGRAM_BUSINESS = 'instagram-business';
export const FACEBOOK = 'facebook';

//Source Type
export const SOURCE_TYPES = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  INSTAGRAM_BASIC: 'instagramBasic',
  INSTAGRAM_BUSINESS: 'instagramBusiness',
};

export const SOURCE_TYPE_DISPLAY_NAMES = {
  [SOURCE_TYPES.INSTAGRAM]: 'Instagram Creator',
  [SOURCE_TYPES.INSTAGRAM_BUSINESS]: 'Instagram Creator',
  [SOURCE_TYPES.INSTAGRAM_BASIC]: 'Instagram',
  [SOURCE_TYPES.FACEBOOK]: 'Facebook',
};

//Media Type
export const MEDIA_TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
};

//Icon Map
export const SERVICE_ICON_MAP = {
  instagramBasic: faInstagram,
  instagramBusiness: faInstagram,
  instagram: faInstagram,
  facebook: faFacebook,
  twitter: faTwitter,
};

export const SERVICE_COLOR_MAP = {
  [SOURCE_TYPES.INSTAGRAM_BASIC]: '--color-instagram',
  [SOURCE_TYPES.INSTAGRAM_BUSINESS]: '--color-instagram',
  [SOURCE_TYPES.INSTAGRAM]: '--color-instagram',
  [SOURCE_TYPES.FACEBOOK]: '--color-facebook',
};

// Regex
export const REGEX_PASSWORD = '^(?=.*?[a-z])(?=.*?[0-9]).{8,}$';
export const PASSWORD_REQUIREMENT =
  'Password must be at least 8 characters and be a mixture of letters and numbers';

// UseMessage Types
export const MESSAGE_ERROR = 'error';
export const MESSAGE_INFG = 'info';
export const MESSAGE_SUCCESS = 'success';

// UseMessage Contents
export const MESSAGE_EMAIL_UNVERIFIED = 'User is not verified, please check your email.';
export const MESSAGE_INVALID_LOGIN = 'Invalid email or password.';
export const MESSAGE_REGISTER_SUCCESS = 'Please check your email and verify your Account.';
export const MESSAGE_REGISTER_FAILURE = 'Email is already in use or account failed to register.';
export const MESSAGE_CHANGE_PASSWORD_FAILURE = 'Password failed to update. Please try again.';
export const MESSAGE_CHANGE_PASSWORD_SUCCESS = 'Password successfully updated.';
export const MESSAGE_FORGOT_SUCCESS = 'Please check your email to reset your password.';
export const MESSAGE_FORGOT_FAILURE = 'Unable to send an email to this Account.';
export const MESSAGE_ADD_SOCIAL_PAGE_FAILURE =
  'Uh oh! We were unable to connect this social account, please try again.';
export const MESSAGE_ADD_PAGE_FAILURE =
  'Uh oh! We were unable to backup this page, please try again.';
export const MESSAGE_ADD_PAGE_NONE_FAILURE = 'Uh oh! Please select and add a page to proceed.';
export const MESSAGE_BILLING_CARD_SUCCESS = 'Your payment method has been successfully updated.';
export const MESSAGE_BILLING_CARD_FAILURE =
  'Uh oh! We were unable to add that card. Please try again.';
export const MESSAGE_BILLING_CARD_DELETE_FAILURE =
  'Uh oh! We were unable to delete that card. Please try again.';
export const MESSAGE_BILLING_CARD_DELETE_SUCCESS = 'Success! The card has been deleted.';
export const MESSAGE_BILLING_PROMO_CODE = 'Uh oh! We were unable to add that promo code.';
export const MESSAGE_BILLING_SUBSCRIBE_FAILURE =
  'Uh oh! We were unable to subscribe your account. Please refresh the page and try again.';
export const MESSAGE_BILLING_REVIEW_FAILURE =
  'Uh oh! We were unable to validate your billing status. Please review your billing information and try again.';
export const MESSAGE_DELETE_ACCOUNT_FAILURE =
  'Uh oh! There was an issue while deleting your account, please try again or reach out for assistance.';
export const MESSAGE_DELETE_ACCOUNT_SUCCESS =
  'We are sorry to see you go! Your account and all of the content we have stored, has been successfully deleted.';
export const MESSAGE_UPDATE_ACCOUNT_SUCCESS = 'Your account has been updated.';
export const MESSAGE_UPDATE_ACCOUNT_FAILURE =
  'Uh oh! There was an issue while updating your account, please try again or reach out for assistance.';
export const MESSAGE_SOURCE_REAUTHENTICATION_SUCCESS =
  'Your page has been re-authenticated successfully.';
export const MESSAGE_SOURCE_REAUTHENTICATION_FAILURE =
  'An error Occurred while re-authenticating your page, please try again.';
export const MESSAGE_SOURCE_REAUTHENTICATION_ERROR =
  'We experienced an error with your connected page. Please re-authenticate.';
export const MESSAGE_GOOGLE_DRIVE_SUCCESS = 'Your Google Drive was linked successfully.';
export const MESSAGE_GOOGLE_DRIVE_FAILURE =
  'Uh oh! There was an error linking your Google Drive, please try again.';
export const MESSAGE_GOOGLE_DRIVE_LOGOUT_SUCCESS = 'Your Google Drive was un-linked successfully.';
export const MESSAGE_GOOGLE_DRIVE_LOGOUT_FAILURE =
  'Uh oh! There was an error un-linking your Google Drive, please try again.';
export const MESSAGE_ACCOUNT_ALREADY_CONNECTED =
  "Your account is already connected. If you're trying to connect a new account, please log out of the social media platform's website and log back in with the new account.";
export const MESSAGE_DROPBOX_SUCCESS = 'Your Dropbox was linked successfully.';
export const MESSAGE_DROPBOX_FAILURE =
  'Uh oh! There was an error linking your Dropbox, please try again.';
export const MESSAGE_DROPBOX_LOGOUT_SUCCESS = 'Your Dropbox was un-linked successfully.';
export const MESSAGE_DROPBOX_LOGOUT_FAILURE =
  'Uh oh! There was an error un-linking your Dropbox, please try again.';

export const MESSAGE_SOURCE_DELETE_FAILURE =
  'Uh oh! There was an error deleting this source, please try again.';
export const MESSAGE_SOURCE_DELETE_LOADING = 'Deleting source data. This may take a few minutes.';
export const MESSAGE_SOURCE_DELETE_SUCCESS = 'This source has been deleted successfully.';
export const MESSAGE_SOURCE_ALREADY_CREATED = 'Your account already has a source.';

export const INSTAGRAM_BASIC_PERMISSIONS = 'user_profile,user_media';
export const INSTAGRAM_BUSINESS_PERMISSIONS =
  'business_basic%2Cbusiness_manage_comments%2Cbusiness_content_publish';

// Price Tier Constants
export const BASIC_TIER_STORAGE_LIMIT = 1000;
export const PREMIUM_TIER_STORAGE_LIMIT = 20000;
export const BUSINESS_TIER_STORAGE_LIMIT = 100000;

export const EMPTY_CHANNEL = 'empty-channel';

export const BILLING_STATUS_ACTIVE = 'active';

export const TRACKING_EVENTS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  EMAIL_LINK_COPY: 'email_link_copy',
  REGISTER: 'signup',
  REGISTER_FAIL: 'signup_fail',
  EMAIL_SUBMIT: 'email_submit',
  EMAIL_SUBMIT_FAIL: 'email_submit_fail',
  PASSWORD_CHANGE: 'password_change',
  PASSWORD_CHANGE_FAIL: 'password_change_fail',
};

export const AUTH_METHOD_PROVIDERS = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  PASSWORD: 'password',
} as const;
